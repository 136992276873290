<style lang="scss" scoped>
</style>
<template>
  <el-dialog
    :title="props.isEdit ? '編輯規格' : '新增規格'"
    v-model="state.dialogFormVisible"
    width="600px"
  >
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <el-form-item
        label="名称"
        :label-width="state.formLabelWidth"
        prop="name"
        v-if="!props.isEditPass"
      >
        <el-input
          v-model="state.form.name"
          autocomplete="off"
          placeholder="請輸入規格名稱"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="屬性"
        class="width_100"
        :label-width="state.formLabelWidth"
        prop="attribute"
      >
        <el-cascader
          class="width_100"
          :options="props.specOption"
          clearable
          placeholder="請選擇屬性"
          v-model="state.form.attribute"
          :props="state.prop"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="狀態"
        prop="status"
        :label-width="state.formLabelWidth"
      >
        <el-select placeholder="請選擇" v-model="state.form.status">
          <el-option label="正常" value="1"></el-option>
          <el-option label="禁用" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="狀態"
        prop="status"
        :label-width="state.formLabelWidth"
      >
        <el-radio v-model="state.radio" label="1">選擇</el-radio>
        <el-radio v-model="state.radio" label="2">滑動</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    specOption: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证

    // state
    const state = reactive({
      options: [], // 屬性下拉框數據
      dialogFormVisible: props.dialogVisible,
      form: props.form,
      addFrom: "",
      formLabelWidth: "70px",
      addRules: {
        name: [{ required: true, message: "請輸入規格名稱", trigger: "blur" }],
        status: [{ required: true, message: "請選擇狀態", trigger: "blur" }],
      },
      prop: {
        multiple: true,
      },
      radio: '1',
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => props.form,
      (val, old) => {
        state.form = val;
        state.radio = '' + val.attr_type
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods

    const add_submit = () => {
      // 新增規格
      state.addFrom.validate(async (valid) => {
        if (!valid) return;
        emit("reloadData", state.form,state.radio);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props };
  },
};
</script>
