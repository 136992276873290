<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
.el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
.el-checkbox {
  box-sizing: border-box;
  padding: 5px 20px;
  line-height: 25px;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- <el-alert
      title="有二級分類請選擇 二級分類，否則無法新增規格和獲取規格數據"
      type="warning"
      :closable="false"
      style="margin-bottom: 20px"
    >
    </el-alert> -->
    <div class="flex_lr_m" style="margin-bottom: 30px">
      <span style="font-size: 14px">分類：</span>
        <el-cascader
        v-model="state.classify"
        :options="state.options"
        :props="{
          expandTrigger: 'hover',
        }"
        @visible-change="cascaderChange"
        placeholder="選擇分類："
        class="flex1"
      ></el-cascader>
    </div>

    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button
          type="primary"
          size="mini"
          @click="addSpec"
          :disabled="state.cla_dis"
          >新增規格</el-button
        >
        <el-button
          type="danger"
          size="mini"
          :disabled="state.delAccountId.length === 0"
          @click="del_accout"
          >批量刪除</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      @selection-change="selection_change"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="expand">
        <template #default="props">
          <el-tag
            closable
            :disable-transitions="false"
            v-for="item in props.row.goods_attr_combine"
            :key="item"
            @close="handleClose(item.id)"
          >
            {{ item.attr_values_name }}
          </el-tag>
          <span v-if="!props.row.goods_attr_combine.length"
            >該規格暫無屬性</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="id" label="規格id" min-width="70">
      </el-table-column>
      <el-table-column prop="attr_key_name" label="規格名稱" min-width="80">
      </el-table-column>
      <el-table-column prop="attr_type" label="選擇方式" min-width="70">
        <template #default="scope">
          <span>{{ scope.row.attr_type == 1 ? "選擇" : "滑動" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="狀態">
        <template #default="scope">
          <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="操作"
        min-width="180px"
        fixed="right"
      >
        <template #default="scope">
          <div class="operation height_100 flex_c_m" style="flex-wrap: wrap">
            <el-button type="primary" size="mini" @click="editInfo(scope.row)"
              >編輯</el-button
            >
            <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
              >刪除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 彈窗 -->
    <pop-up
      :dialogVisible="state.dialogVisible"
      @toggleDialogVisible="toggleDialogVisible"
      @reloadData="reloadData"
      :name="state.name"
      :specOption="state.specOption"
      :form="state.form"
      :isEdit="state.isEdit"
    />
    <!--新增属性弹窗-->
    <attribute-pop
      :dialogVisible="state.attributeDialogVisible"
      @toggleDialogVisible="toggleDialogVisible"
    ></attribute-pop>
    <!-- 提示框 -->
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, nextTick, computed } from "vue";
import PopUp from "./components/pop-up";
import AttributePop from "./components/attribute-pop";

export default {
  components: {
    PopUp,
    AttributePop,
  },
  setup() {
    const req_table_data = () => {
      $http.getGoodsAttr(state.user).then((res) => {
        if (res.status !== 200) return;
        if (!res.data.length) return;
        state.tableData = res.data[0].goods_attr_keys;
        state.count = res.data[0].goods_attr_keys_count;
      });
    };

    const state = reactive({
      name: "",
      tableData: [], // table的数据
      user: {
        limit: 10,
        page: 1,
        goods_sort_id: -1,
      },
      count: "", // 總條數
      seach(data) {
        state.user.multi = data.seatch_name;
        state.user.status = data.region;
        req_table_data();
      },
      dialogVisible: false, // 弹窗
      attributeDialogVisible: false, // 屬性彈窗
      dialog: false, // 提示框
      delAccountId: [], // 批量刪除的id
      delUserId: [],
      classify: [], // 多級選擇的值
      userId: "",
      oldClassify: [], // 上一次選擇的值
      options: [
        // 多級分類數據
      ],
      inputVisible: false,
      inputValue: "",
      input: "",
      specOption: [], // 属性列表
      form: {
        name: "",
        status: "1",
        attribute: [], // 屬性選擇的值
      },
      cla_dis: true,
      isEdit: false,
    });

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message } = proxy;

    req_table_data();

    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    const refresh = () => {
      req_table_data();
    };

    const toggleDialogVisible = () => {
      state.dialogVisible = false;
      state.attributeDialogVisible = false;
      const timer = setTimeout(() => {
        clearTimeout(timer);
      }, 200);
    };

    const reloadData = async (data, radio) => {
      if (!state.isEdit) {
        const res = await $http.addGoodsAttr({
          goods_sort_id: state.classify[state.classify.length - 1],
          attr_key_name: data.name,
          status: data.status,
          attr_values_id: data.attribute.map((item) => (item = item[0])),
          attr_type: radio,
        });
        if (res.status !== 200) return;
        $message.success("添加成功");
      } else {
        const res = await $http.editGoodsAttr({
          id: data.id,
          attr_key_name: data.name,
          attr_values_id: data.attribute.map((item) => {
            item = typeof item !== "number" ? item[0] : item;
            return item;
          }),
          status: data.status,
          goods_sort_id: data.goods_sort_id,
          attr_type: radio,
        });
        if (res.status !== 200) return;
        $message.success("修改成功");
      }
      req_table_data();
    };

    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    const dels_confirm = async () => {
      const res = await $http.delGoodsAttr({
        id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    const editInfo = async (data) => {
      const ids = [];
      data.goods_attr_combine.forEach((item) => {
        ids.push(item.attr_values_id);
      });
      state.isEdit = true;
      state.dialogVisible = true;
      state.form = {
        name: data.attr_key_name,
        status: data.status.toString(),
        attribute: ids, // 屬性選擇的值
        id: data.id,
        goods_sort_id: data.goods_sort_id,
        attr_type:data.attr_type
      };
      const res = await $http.geAlltGoodsAttrValues();
      if (res.status !== 200) return;
      state.specOption = [];
      res.data.forEach((item) => {
        const obj = {
          value: item.id,
          label: item.attr_values_name,
        };
        state.specOption.push(obj);
      });
    };

    // 刪除用戶
    const deleteInfo = (data) => {
      state.delUserId = [data.id];
      state.dialog = true;
    };

    const cascaderChange = (e) => {
      // 選擇級別隱藏
      setTimeout(() => {
        if (
          e ||
          state.classify.sort().toString() ==
            state.oldClassify.sort().toString()
        )
          return;
        state.oldClassify = JSON.parse(JSON.stringify(state.classify));
        if (!state.classify.length) return (state.cla_dis = true);
        state.user.goods_sort_id = state.classify[state.classify.length - 1];
        req_table_data();
        return (state.cla_dis = false);
      }, 200);
    };

    const handleClose = async (id) => {
      // 刪除屬性標簽
      const res = await $http.delGoodsAttrCombine({
        id,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
    };

    const showInput = (id) => {
      state.tableData.filter((item) => item.id === id)[0].inputVisible = true;
      state.attributeDialogVisible = true;
    };

    const handleInputConfirm = (id) => {
      if (state.tableData.filter((item) => item.id === id)[0].inputValue) {
        state.tableData
          .filter((item) => item.id === id)[0]
          .children.push(
            state.tableData.filter((item) => item.id === id)[0].inputValue
          );
        $message.success("新增成功");
      }
      state.tableData.filter((item) => item.id === id)[0].inputVisible = false;
      state.tableData.filter((item) => item.id === id)[0].inputValue = "";
    };

    const focus = async () => {
      state.options = [];
      const { data } = await $http.getAllGoodsSort();
      Object.values(data).forEach((item) => {
        const obj = {
          value: item.id,
          label: item.sort_name,
        };
        if (Object.values(item.children).length) {
          obj.children = [];
          Object.values(item.children).forEach((curr) => {
            obj.children.push({
              value: curr.id,
              label: curr.sort_name,
            });
          });
        }
        state.options.push(obj);
      });
    };
    focus();

    // 添加规格
    const addSpec = async () => {
      state.dialogVisible = true;
      state.isEdit = false;
      state.name = "";
      state.form = {
        name: "",
        status: "1",
        attribute: [], // 屬性選擇的值
      };
      const res = await $http.geAlltGoodsAttrValues();
      if (res.status !== 200) return;
      state.specOption = [];
      res.data.forEach((item) => {
        state.specOption.push({
          value: item.id,
          label: item.attr_values_name,
        });
      });
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      toggleDialogVisible,
      reloadData,
      selection_change,
      del_accout,
      dels_confirm,
      editInfo,
      deleteInfo,
      cascaderChange,
      handleClose,
      showInput,
      handleInputConfirm,
      focus,
      addSpec,
    };
  },
};
</script>
