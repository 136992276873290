<style lang="scss" scoped>
</style>
<template>
  <el-dialog title="新增屬性" v-model="state.dialogFormVisible" width="400px">
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <el-form-item
        label="屬性名"
        :label-width="state.formLabelWidth"
        prop="name"
        v-if="!props.isEditPass"
      >
        <el-input
          v-model="state.form.name"
          autocomplete="off"
          placeholder="請輸入屬性名稱"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="庫存"
        prop="inventory"
        :label-width="state.formLabelWidth"
      >
        <el-input-number
          :min="0"
          v-model="state.form.inventory"
          placeholder="請輸入庫存"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证

    // state
    const state = reactive({
      dialogFormVisible: props.dialogVisible,
      form: {
        name: props.name,
        inventory: 1, // 屬性庫存
      },
      addFrom: "",
      formLabelWidth: "70px",
      addRules: {
        name: [{ required: true, message: "請輸入屬性名稱", trigger: "blur" }],
        inventory: [
          { required: true, message: "請輸入屬性庫存", trigger: "blur" },
        ],
      },
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods

    const add_submit = () => {
      // 新增規格
      state.addFrom.validate(async (valid) => {
        if (!valid) return;
        // const res = await $http.updatePass({
        //   password: state.form.pass,
        //   id: props.userId,
        // });
        // if (res.status !== 200) return;
        $message.success("操作成功");
        console.log("新增的規格名稱:" + state.form.name, state.form.status);
        emit("reloadData", true);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props };
  },
};
</script>
